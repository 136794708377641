import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import Header from "layouts/profile/components/Header";
import {
  Alert,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import { useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import secureLocalStorage from "react-secure-storage";
import { getOnboardingRequestById } from "api_services/MemberService";
import { updateRequestApprovedStatus } from "api_services/MemberService";
import { useFormik } from "formik";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { ThemeProvider } from "@emotion/react";
import { onboardingSchema } from "layouts/validationSchema/onboardingSchema";
import MDInput from "components/MDInput";
import { copyFileToBucket } from "api_services/UserService";
import { addNewMember } from "api_services/MemberService";
import { addNewUser } from "api_services/UserService";
import { updateMemberContactList } from "api_services/MemberService";
import { getUserByEmail } from "api_services/UserService";
import { updateRequestHiddenStatus } from "api_services/MemberService";

function getCurrentDate() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(date.getFullYear());
  return yyyy + "-" + mm + "-" + dd;
  // return a date in the form yyyy-mm-dd
}

const containerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#9BE06A",
      darker: "#9BE06A",
    },
  },
});

function OnboardingRequest() {
  const { id } = useParams();

  const roleNames = [
    { title: "Company Admin", val: "COMPANY_ADMIN" },
    { title: "Super Admin", val: "SUPER_ADMIN" },
    { title: "Tech Admin", val: "TECH_ADMIN" },
    { title: "System User", val: "SYS_USER" },
    { title: "Company Staff", val: "COMPANY_STAFF" },
    { title: "Anonymous User", val: "ANONYMOUS_USER" },
  ];

  const editor = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.EDIT_ACCESS
  ];
  const hasCompanyAccess = JSON.parse(secureLocalStorage.getItem("current-user-data"))[
    ROLE_ACCESS.COMPANY_ACCESS
  ][id];
  const userToken = secureLocalStorage.getItem("custom-token");
  const userIds = [];

  const [memberData, setMemberData] = useState({});
  const [memberAddress, setMemberAddress] = useState([]);
  const [profileText, setProfileText] = useState("");
  // const [userList, setUserList] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [profileValidateMsg, setProfileValidateMsg] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const [date, setDate] = useState(getCurrentDate());
  const [userRole, setUserRole] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const validateEditorContent = (content) => {
    if (!content || content.trim() === "") {
      setProfileValidateMsg("Company profile is required !");
    }
  };

  async function createNewMember(memberData) {
    const response = await addNewMember(memberData, userToken);
    if (response.id !== null) {
      //console.log("member created Successfully", response.id);
      return response.id;
    } else {
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log("member creation failed");
    }
  }

  async function createNewUser(userData, exists, memberId) {
    if (exists) {
      await editContactList(userData.id, userData.displayName, memberId);
    } else {
      const response = await addNewUser(userData, userToken);
      console.log("New user created", response);
      if (response.id) {
        await editContactList(response.id, response.displayName, memberId);
      } else {
        setSaving(false);
        setPositionState({ open: true, vertical: "top", horizontal: "center" });
      }
    }
  }

  const handleUserRoleChange = (id, e) => {
    setUserRole((currentRoles) => {
      const updatedRoles = [...currentRoles];
      updatedRoles[id] = roleNames.find((item) => item.val === e);
      return updatedRoles;
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleDenyClose = () => {
    setOpenDenyDialog(false);
  };

  const handleDenyOpen = () => {
    setOpenDenyDialog(true);
  };

  const handleCheckChange = (event) => {
    const { value } = event.target;

    if (values.conferences.includes(value)) {
      setFieldValue(
        "conferences",
        values.conferences.filter((option) => option !== value)
      );
      return;
    }

    setFieldValue("conferences", [...values.conferences, value]);
  };

  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  useEffect(() => {
    async function getRequestByID() {
      const response = await getOnboardingRequestById(id, userToken);

      if (response.data !== null) {
        const data = response.data.attributes;
        setIsLoading(false);
        setContactsList(data.contacts);
        setMemberData(data);
        setMemberAddress(data.address);
        setUserRole(
          Array.from({ length: data.contacts.length }, () => ({
            title: "Company Staff",
            val: "COMPANY_STAFF",
          }))
        );
        // setLatitude(data.location.latitude);
        // setLongitude(data.location.longitude);
        setProfileText(data.companyProfile);
      } else {
        setIsLoading(true);
        //console.log(response);
      }
    }
    getRequestByID();
  }, []);

  const approveAndCreateMember = async () => {
    setSaving(true);
    const memberId = await onboardMember();
    await onboardContacts(memberId);
    await updateRequestApprovedStatus(id, true);
    setSaving(false);
    navigate("/onboarding_requests");
    handleClose();
  };

  const denyRequest = async () => {
    setSaving(true);
    await updateRequestHiddenStatus(id, true);
    setSaving(false);
    navigate("/onboarding_requests");
    handleClose();
  };

  const onboardMember = async () => {
    validateEditorContent(profileText);
    if (!(profileText == "")) {
      const profileImgDownloadUri = await uploadProfilePicture(memberData.companyLogo);

      const memberObj = {
        tenantId: "FW",
        companyName: values.companyName,
        websiteUrl: values.websiteUrl,
        isHeadOffice: true,
        linkedInUrl: values.linkedInUrl,
        officeContactNumber: values.officeContactNumber,
        file: null,
        companyProfile: profileText,
        companyLogo: profileImgDownloadUri,
        email: values.email,
        approvedTimestamp: Date.parse(values.approvedTimestamp),
        expiryDate: Date.parse(values.expiryDate),
        address: [
          {
            city: values.city,
            unitNo: values.unitNo,
            country: values.country,
            region: GENERAL_CONSTS.REGIONS[values.country]["region"],
            subregion: GENERAL_CONSTS.REGIONS[values.country]["subregion"],
            postcode: values.postcode,
          },
        ],
        location: {
          latitude: values.latitude,
          longitude: values.longitude,
        },
        showBanner: showBanner,
        bannerTitle: values.bannerTitle,
        bannerBody: values.bannerBody,
        marketsToDevelop: values.marketsToDevelop,
        conferences: values.conferences,
        memberType: values.memberType,
        memberFee: values.memberFee,
      };
      //console.log("AAA :", memberObj);
      return createNewMember(memberObj);
    }
  };

  const onboardContacts = async (memberId) => {
    for (let index = 0; index < contactsList.length; index++) {
      const contact = contactsList[index];
      try {
        //check if user already exists
        const userData = await getUserByEmail(contact.email, userToken);
        await createNewUser(userData, true, memberId);
      } catch (e) {
        //throw error if not exists
        const profileImgDownloadUri = await uploadProfilePicture(contact.avatar);
        const userObj = {
          tenantId: "FW",
          status: "active",
          email: contact.email,
          roles: [userRole[index]["val"]],
          displayName: contact.fullName,
          title: contact.title,
          phoneNumber: contact.phoneNumber,
          avatar: profileImgDownloadUri,
          linkedinUrl: contact.linkedinUrl,
          conferences: [],
          hasWhatsapp: false,
        };
        console.log("AA User object", userObj);
        console.log("AA Member Id", memberId);
        await createNewUser(userObj, false, memberId);
      }
    }
  };

  async function editContactList(userId, displayName, memberId) {
    const response = await updateMemberContactList(memberId, userId, displayName, userToken);
    if (response) {
      //console.log("Contact list updated successfully", response.contacts);
    } else {
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  const onSubmit = async (e) => {
    // console.log("AA UserRoles: ", userRole);
    // console.log("Onboard Data: ", memberData);
    try {
      handleOpen();
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log(e.message);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: memberData.companyName,
      websiteUrl: memberData.websiteUrl,
      isHeadOffice: true,
      linkedInUrl: memberData.linkedInUrl,
      officeContactNumber: memberData.officeContactNumber,
      companyProfile: memberData.companyProfile,
      companyLogo: memberData.companyLogo,
      email: memberData.email,
      approvedTimestamp: date,
      expiryDate: date,
      city: memberAddress.city,
      unitNo: memberAddress.street,
      country: memberAddress.country,
      postcode: memberAddress.postcode,
      latitude: "",
      longitude: "",
      showBanner: false,
      bannerTitle: "",
      bannerBody: "",
      marketsToDevelop: memberData.marketsToDevelop,
      conferences: [],
      memberType: "",
      memberFee: 0,
    },
    validationSchema: onboardingSchema,
    onSubmit,
  });

  async function uploadProfilePicture(imageUrl) {
    if (imageUrl) {
      const paths = imageUrl.split("/");
      const bucketName = paths[2].split(".")[0];
      const response = await copyFileToBucket(
        "fc-doc-store-dev",
        GENERAL_CONSTS.DOCUMENT_UPLOAD_ROOT_FOLDER +
          GENERAL_CONSTS.MEMBER_PROFILE_IMAGES_ROOT_FOLDER,
        bucketName,
        paths[paths.length - 1],
        userToken
      );
      if (response) {
        return response.substring(0, response.indexOf("?"));
      } else {
        //console.log("Image upload failed");
      }
    } else {
      return null;
    }
  }

  //console.log("contact list : ", contactsList);
  const { t } = useTranslation();

  ////////////////////////////////////////////
  // member location map ///////////////////////

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const { latitude, longitude } = position.coords;
  //       // Implement reverse geocoding here to convert coordinates to address
  //       // Set the user address, latitude, and longitude in state
  //       setLatitude(latitude);
  //       setLongitude(longitude);
  //     });
  //   } else {
  //    //console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  // useEffect(() => {
  //   const markerImages = document.getElementsByClassName("ol-image");
  //   if (markerImages.length > 0) {
  //     const markerImageElement = markerImages[0];
  //     markerImageElement.style.width = "32px";
  //     markerImageElement.style.height = "32px";
  //   }
  // }, []);

  //console.log("Location", latitude, longitude);
  // member location map ///////////////////////

  const isExpired = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getTime() >= date2.getTime();
  };

  const linkedContacts = contactsList?.map(
    ({ id, avatar, fullName, title, email, phoneNumber, linkedinUrl }, index) => (
      <Grid item xs={12} sm={12} md={5.9} xl={5.9} mb={2}>
        <Card sx={{ px: 2, pt: 2, mt: 2, height: "100%" }}>
          <Grid container spacing={1} display="flex" justifyContent="space-between">
            <Grid
              item
              xs={6}
              sm={3}
              md={2.5}
              xl={2.5}
              component={Link}
              to={`/contacts/profile/${id}`}
            >
              <CardMedia
                src={avatar}
                component="img"
                variant="square"
                mr={2}
                sx={{
                  width: "100%",
                  mb: { xs: 3, sm: 3, md: 0 },
                  margin: 0,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>

            <Grid item xs={12} md={8.5} xl={8.5} display="flex" flexDirection="column">
              <MDBox mt={-0.5} display="flex" justifyContent="space-between" alignItems="start">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color="black"
                  // component={Link}
                  // to={`/contacts/profile/${id}`}
                >
                  {fullName}
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="black">
                      {title}
                    </MDTypography>
                  </MDBox>
                </MDTypography>

                <MDBox
                  component="a"
                  href={linkedinUrl}
                  target="_blank"
                  variant="button"
                  color="info"
                >
                  <LinkedInIcon fontSize="medium" />
                </MDBox>
              </MDBox>

              <MDBox variant="button" color="primary" mt={1} display="flex" alignItems="center">
                <EmailIcon fontSize="small" />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="black"
                  ml={2}
                  component="a"
                  href={`mailto:${email}`}
                  target="_blank"
                >
                  {email}
                </MDTypography>
              </MDBox>
              <MDBox variant="button" color="primary" mt={1} display="flex" alignItems="center">
                <PhoneIcon fontSize="small" />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="black"
                  ml={2}
                  component="a"
                  href={`tel:${phoneNumber}`}
                  target="_blank"
                >
                  {phoneNumber}
                </MDTypography>
              </MDBox>
              <MDBox mt={2}>
                <FormControl container fullWidth>
                  <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                  <Select
                    label={"User Role"}
                    id="userRole"
                    fullWidth
                    value={userRole[index].val}
                    labelId="demo-simple-select-label"
                    onChange={(e) => handleUserRoleChange(index, e.target.value)}
                    sx={{ height: 36 }}
                  >
                    {roleNames.map((type) => (
                      <MenuItem key={type.val} value={type.val}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  );

  return (
    <>
      <style>
        {` .ql-snow .ql-stroke , .ql-snow .ql-picker {
            stroke: #7b809a;
            color:#7b809a;
            font-family: 'poppins;
          }
          .ql-snow .ql-picker.ql-expanded .ql-picker-options {
            z-index: 9;
          }
          #map li {
            display:none;
          }

          .ol-image{
            width:"50";
          }
          
          `}
      </style>
      <DashboardLayout>
        <DashboardNavbar />
        <Header>
          {isLoading ? (
            // Render a spinner or loading indicator
            <Stack spacing={3}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "0.8rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <MDBox display="flex">
                <Skeleton variant="circular" width={160} height={140} sx={{ mr: 5 }} />
                <Skeleton variant="rectangular" width={"100%"} height={140} />
              </MDBox>

              <Skeleton variant="rectangular" width={"100%"} height={20} />
              <Skeleton variant="rounded" width={"100%"} height={200} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Stack>
          ) : (
            <MDBox mx={1} mb={2} component="form" role="form" onSubmit={handleSubmit}>
              <Grid container spacing={1} display="flex" justifyContent="space-between">
                <Grid
                  item
                  xs={12}
                  md={6}
                  xl={5}
                  my={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <MDBox>
                    <MDTypography variant="h5" fontWeight="medium" color="black">
                      {memberData.companyName}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>

              <Grid container spacing={1} display="flex" justifyContent="space-between" mt={5}>
                <Grid item xs={8} md={2} xl={2}>
                  <CardMedia
                    src={memberData.companyLogo}
                    component="img"
                    variant="square"
                    sx={{
                      width: "124.8px",
                      height: "124.8px",
                      mb: { xs: 3, sm: 3, md: 0 },
                      margin: 0,
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  display="flex"
                  justifyContent="start"
                  flexDirection="column"
                >
                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Country : </b>
                      <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                        {memberAddress?.country}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>

                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="start">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Address : </b>
                      <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                        {memberAddress?.street +
                          ", " +
                          memberAddress?.city +
                          ", " +
                          memberAddress?.country +
                          ", " +
                          memberAddress?.postcode +
                          "."}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>

                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Email : </b>
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        mr={1}
                        color="black"
                        component="a"
                        href={`mailto:${memberData.email}`}
                        target="_blank"
                      >
                        {memberData.email}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  display="flex"
                  justifyContent="start"
                  flexDirection="column"
                >
                  <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Office No : </b>
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        mr={1}
                        color="black"
                        component="a"
                        href={`tel:${memberData.officeContactNumber}`}
                        target="_blank"
                      >
                        {memberData.officeContactNumber}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1.5} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>LinkedIn :</b>
                    </MDTypography>
                    <MDBox
                      component="a"
                      href={memberData.linkedInUrl}
                      target="_blank"
                      variant="button"
                      fontWeight="light"
                      color="black"
                    >
                      <LinkedInIcon fontSize="medium" color="info" />
                    </MDBox>
                  </MDBox>

                  <MDBox mb={1.5} lineHeight={0} display="flex" alignItems="center">
                    <MDTypography variant="button" fontWeight="light" mr={1} color="black">
                      <b>Website :</b>
                    </MDTypography>
                    <MDBox
                      component="a"
                      href={memberData.websiteUrl}
                      target="_blank"
                      variant="button"
                      fontWeight="light"
                      color="black"
                    >
                      <LanguageIcon fontSize="medium" color="primary" />
                    </MDBox>
                  </MDBox>
                </Grid>

                {/* Locations shown on map */}
                {/* <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Location
                  </MDTypography>
                  <MDBox mt={2}>
                    {latitude && longitude ? (
                      <div id="map" style={{ width: "100%", height: "250px" }}></div>
                    ) : (
                      <MDTypography
                        variant="button"
                        display="flex"
                        justifyContent="start"
                        color="black"
                      >
                        No Location found !
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid> */}

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Company Profile :
                  </MDTypography>
                  <MDBox mt={1}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      color="black"
                      align="justify"
                      dangerouslySetInnerHTML={{ __html: memberData.companyProfile }}
                    ></MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Markets Looking to Develop In :
                  </MDTypography>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "center",
                      overflowX: "auto",
                      minHeight: 42.4,
                      gap: 1,
                      color: "white",
                    }}
                    mt={1}
                  >
                    {memberData.marketsToDevelop.map((value) => (
                      <Chip
                        key={value}
                        label={
                          <MDTypography variant="button" color="black">
                            {value}
                          </MDTypography>
                        }
                      />
                    ))}
                  </MDBox>
                </Grid>
                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={4}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={2} ml={1}>
                      Other Profile Information
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={6} xl={6} mt={2}>
                  <MDBox display="flex" alignItems="center" px={0.5}>
                    <FormControl
                      container
                      fullWidth
                      error={errors.memberType && touched.memberType && true}
                      focused={
                        (errors.memberType && touched.memberType && true) ||
                        (values.memberType && true)
                      }
                    >
                      <InputLabel id="demo-simple-select-label">
                        {errors.memberType && touched.memberType
                          ? errors.memberType
                          : "Member Type"}
                      </InputLabel>
                      <Select
                        error={errors.memberType && touched.memberType && true}
                        focused={
                          (errors.memberType && touched.memberType && true) ||
                          (values.memberType && true)
                        }
                        label={
                          errors.memberType && touched.memberType
                            ? errors.memberType
                            : "Member Type"
                        }
                        id="memberType"
                        fullWidth
                        {...getFieldProps("memberType")}
                        value={[values.memberType]}
                        labelId="demo-simple-select-label"
                        onChange={handleChange}
                        sx={{ height: 36 }}
                      >
                        {GENERAL_CONSTS.MEMBER_TYPES.map((type, idx) => (
                          <MenuItem key={idx} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6} mt={2} px={0.5}>
                  <MDInput
                    type="number"
                    error={errors.memberFee && touched.memberFee && true}
                    focused={
                      (errors.memberFee && touched.memberFee && true) || (values.memberFee && true)
                    }
                    label={errors.memberFee && touched.memberFee ? errors.memberFee : "Member Fee"}
                    id="memberFee"
                    onChange={handleChange}
                    value={values.memberFee}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox px={0.5}>
                    <MDInput
                      type={"date"}
                      fullWidth
                      error={errors.approvedTimestamp && touched.approvedTimestamp && true}
                      focused={
                        (errors.approvedTimestamp && touched.approvedTimestamp && true) ||
                        (values.approvedTimestamp && true)
                      }
                      label={
                        errors.approvedTimestamp && touched.approvedTimestamp
                          ? errors.approvedTimestamp
                          : "Enrollment Date"
                      }
                      id="approvedTimestamp"
                      onChange={handleChange}
                      value={values.approvedTimestamp}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox px={0.5}>
                    <MDInput
                      error={errors.expiryDate && touched.expiryDate && true}
                      focused={
                        (errors.expiryDate && touched.expiryDate && true) ||
                        (values.expiryDate && true)
                      }
                      label={
                        errors.expiryDate && touched.expiryDate ? errors.expiryDate : "Expiry Date"
                      }
                      id="expiryDate"
                      onChange={handleChange}
                      value={values.expiryDate}
                      onBlur={handleBlur}
                      type={"date"}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mt={4}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" my={2} ml={1}>
                      Location
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0}>
                    <MDTypography
                      variant="button"
                      color="textButton"
                      fontWeight="bold"
                      ml={1}
                      textTransform="capitalize"
                      component="a"
                      href="https://www.latlong.net/convert-address-to-lat-long.html"
                      target="_blank"
                    >
                      Get Lat Long from Address
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.latitude && touched.latitude && true}
                      focused={
                        (errors.latitude && touched.latitude && true) || (values.latitude && true)
                      }
                      label={errors.latitude && touched.latitude ? errors.latitude : "Latitude"}
                      id="latitude"
                      onChange={handleChange}
                      value={values.latitude}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} sm={12} md={6} xl={6}>
                  <MDBox mt={2} px={0.5}>
                    <MDInput
                      type="text"
                      error={errors.longitude && touched.longitude && true}
                      focused={
                        (errors.longitude && touched.longitude && true) ||
                        (values.longitude && true)
                      }
                      label={errors.longitude && touched.longitude ? errors.longitude : "Longitude"}
                      id="longitude"
                      onChange={handleChange}
                      value={values.longitude}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12} mt={3}>
                  <MDBox mx={1} mb={1}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" mb={2}>
                      Conferences
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mx={1} mb={3}>
                    <ThemeProvider theme={theme}>
                      <Grid container>
                        {GENERAL_CONSTS.CONFERENCES.map((conference) => (
                          <Grid item xs={12} md={6} lg={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="conferences"
                                  value={conference.name}
                                  onChange={handleCheckChange}
                                  onBlur={handleBlur}
                                  checked={
                                    values.conferences
                                      ? values.conferences.includes(conference.name)
                                      : false
                                  }
                                />
                              }
                              label={
                                <MDTypography
                                  variant="button"
                                  fontWeight="regular"
                                  color="black"
                                  width="max-content"
                                >
                                  <span style={{ color: "black", fontSize: "11.2px" }}>
                                    {conference.name}
                                  </span>
                                </MDTypography>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </ThemeProvider>
                    {errors.conferences && touched.conferences ? (
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        mb={1}
                        fontSize={12}
                        color="error"
                      >
                        {errors.conferences}
                      </MDTypography>
                    ) : null}
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12} mt={3}>
                  <MDBox mx={1}>
                    <MDTypography variant="h6" fontWeight="medium" color="black" mb={1}>
                      Banner
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid xs={12} md={12} xl={12}>
                  <MDBox mx={1} mb={1}>
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={4}
                      xl={4}
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                        <MDBox mt={0.5}>
                          <Switch
                            checked={showBanner}
                            onChange={() => {
                              setShowBanner(!showBanner);
                            }}
                          />
                        </MDBox>
                        <MDBox width="80%" ml={0.5}>
                          <MDTypography variant="button" fontWeight="regular" color="black">
                            Show Banner on Member Profile
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </MDBox>
                  <Grid xs={12} md={12} xl={12}>
                    <MDBox px={0.5} mb={2}>
                      <MDInput
                        type="text"
                        fullWidth
                        error={errors.bannerTitle && touched.bannerTitle && true}
                        focused={
                          (errors.bannerTitle && touched.bannerTitle && true) ||
                          (values.bannerTitle && true)
                        }
                        label={
                          errors.bannerTitle && touched.bannerTitle
                            ? errors.bannerTitle
                            : "Banner Header"
                        }
                        id="bannerTitle"
                        onChange={handleChange}
                        value={values.bannerTitle}
                        onBlur={handleBlur}
                      />
                    </MDBox>
                  </Grid>
                  <Grid xs={12} md={12} xl={12}>
                    <MDBox px={0.5}>
                      <MDInput
                        type="text"
                        multiline
                        maxRows={5}
                        fullWidth
                        error={errors.bannerBody && touched.bannerBody && true}
                        focused={
                          (errors.bannerBody && touched.bannerBody && true) ||
                          (values.bannerBody && true)
                        }
                        label={
                          errors.bannerBody && touched.bannerBody
                            ? errors.bannerBody
                            : "Banner Text"
                        }
                        id="bannerBody"
                        onChange={handleChange}
                        value={values.bannerBody}
                        onBlur={handleBlur}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} xl={12} mt={2} px={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    mb={2}
                    textTransform="capitalize"
                    color="black"
                  >
                    Contacts :
                  </MDTypography>

                  <MDBox mt={1}>
                    {contactsList?.length !== 0 ? (
                      <Grid container spacing={1} display="flex" justifyContent="space-between">
                        {linkedContacts}
                      </Grid>
                    ) : (
                      <Grid>
                        <MDTypography
                          variant="button"
                          display="flex"
                          justifyContent="start"
                          color="black"
                        >
                          No contacts found !
                        </MDTypography>
                      </Grid>
                    )}
                  </MDBox>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={12}
                  pt={5}
                  mt={5}
                  px={2}
                  gap={1}
                  display="flex"
                  justifyContent="end"
                  alignItems="end"
                >
                  <MDButton
                    size="medium"
                    variant="gradient"
                    color="primary"
                    sx={{ px: 8 }}
                    type="submit"
                  >
                    Approve Request
                  </MDButton>
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="primary"
                    sx={{ px: 8 }}
                    onClick={handleDenyOpen}
                  >
                    Deny Request
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </Header>

        <Footer />
      </DashboardLayout>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Member creation failed!
        </Alert>
      </Snackbar>

      {/* Dialog Popup //////////////////////////////////////////// */}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Are you sure you want to approve this request?
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => approveAndCreateMember()}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={openDenyDialog}
          onClose={handleDenyClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Are you sure you want to deny this request?
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleDenyClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => denyRequest()}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>

      {/* //////////////////////////////////////////// */}
    </>
  );
}

export default OnboardingRequest;
