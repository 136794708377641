export const GENERAL_CONSTS = {
  DOCUMENT_UPLOAD_ROOT_FOLDER: "fc-app2-content/",
  USER_PROFILE_IMAGES_ROOT_FOLDER: "images/users",
  MEMBER_PROFILE_IMAGES_ROOT_FOLDER: "images/directory",
  DOWNLOADS_ROOT_FOLDER: "downloads-fw",
  ICONS_ROOT_FOLDER: "images/icons",
  BANNERS_ROOT_FOLDER: "images/banners",
  ONBOARDING_USER_PROFILE_IMAGES_ROOT_FOLDER: "images/prospects/users",
  ONBOARDING_MEMBER_PROFILE_IMAGES_ROOT_FOLDER: "images/prospects/directory",
  MEMBER_TYPES: ["FOC", "Paid"],
  MEMBER_APPLICATION_URL: "https://www.freytworld.com/become-a-member/",
  CONFERENCES: [
    {
      name: "Bali 2023 Conference",
    },
    {
      name: "Phuket 2024 Conference",
    },
  ],
  CONF_BROCHURE: "FreytMeet_Brochure.pdf",
  CONF_PRICING: {
    delegate_pass: 1299,
    spouse_pass: 699,
    golf_day: 200,
    booth: 3000,
  },
  CONF_SPONSORSHIP_TYPE: [
    {
      key: "platinum_sponsor",
      name: "Platinum Sponsor",
      price: 4000,
    },
    {
      key: "gold_sponsor",
      name: "Gold Sponsor",
      price: 3000,
    },
    {
      key: "onetoonemeeting_sponsor",
      name: "1:1 Meeting Sponsor",
      price: 3000,
    },
    // {
    //   key: "coffeebreak_sponsor",
    //   name: "Coffee Break Sponsor",
    //   price: 2000,
    // },
    {
      key: "lanyard_sponsor",
      name: "Lanyard Sponsor",
      price: 3000,
    },
    {
      key: "golf_sponsor",
      name: "Golf Sponsor",
      price: 2000,
    },
    {
      key: "tablenumber_sponsor",
      name: "Table Number Sponsor",
      price: 3000,
    },
    {
      key: "conferencedirectory_sponsor",
      name: "Conference Directory Sponsor",
      price: 3000,
    },
    {
      key: "flask_sponsor",
      name: "Flask Sponsor",
      price: 2000,
    },
    {
      key: "teambuilding_sponsor",
      name: "Team Building Sponsor",
      price: 3000,
    },
    {
      key: "pen_sponsor",
      name: "Pen Sponsor",
      price: 2000,
    },
    {
      key: "stapler_sponsor",
      name: "Stapler Sponsor",
      price: 2000,
    },
    {
      key: "massage_sponsor",
      name: "Massage Sponsor",
      price: 2000,
    },
  ],
  CONF_SHIRT_TYPE: ["Male", "Female"],
  CONF_SHIRT_SIZE: ["SS", "S", "M", "L", "XL", "XXL", "XXXL"],
  COUNTRIES: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua & Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia & Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "England",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kosovo",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Northern Ireland",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of Ireland",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre & Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Scotland",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts & Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks & Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Wales",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],

  CODES: {
    Afghanistan: "AF",
    "Aland Islands": "AX",
    Albania: "AL",
    Algeria: "DZ",
    Andorra: "AD",
    Angola: "AO",
    Anguilla: "AI",
    Antarctica: "AQ",
    "Antigua & Barbuda": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Aruba: "AW",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bermuda: "BM",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia & Herzegovina": "BA",
    Botswana: "BW",
    "Bouvet Island": "BV",
    Brazil: "BR",
    "British Indian Ocean Territory": "IO",
    Brunei: "BN",
    Bulgaria: "BG",
    "Burkina Faso": "BF",
    Burundi: "BI",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Cape Verde": "CV",
    "Cayman Islands": "KY",
    "Central African Republic": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    Colombia: "CO",
    Comoros: "KM",
    Congo: "CG",
    "Cook Islands": "CK",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    "Czech Republic": "CZ",
    "Democratic Republic of the Congo": "CD",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    England: "GB",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Ethiopia: "ET",
    "Falkland Islands": "FK",
    "Faroe Islands": "FO",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    "French Polynesia": "PF",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Gibraltar: "GI",
    Greece: "GR",
    Greenland: "GL",
    Grenada: "GD",
    Guadeloupe: "GP",
    Guam: "GU",
    Guatemala: "GT",
    Guernsey: "GG",
    Guinea: "GN",
    "Guinea Bissau": "GW",
    Guyana: "GY",
    Haiti: "HT",
    Honduras: "HN",
    "Hong Kong": "HK",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    "Republic of Ireland": "IE",
    "Isle Of Man": "IM",
    Israel: "IL",
    Italy: "IT",
    Jamaica: "JM",
    Japan: "JP",
    Jersey: "JE",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kosovo: "XK",
    Kiribati: "KI",
    Korea: "KR",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    "Lao People's Democratic Republic": "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libyan: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Macao: "MO",
    Macedonia: "MK",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    Martinique: "MQ",
    Mauritania: "MR",
    Mauritius: "MU",
    Mayotte: "YT",
    Mexico: "MX",
    Moldova: "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Montserrat: "MS",
    Morocco: "MA",
    Mozambique: "MZ",
    Myanmar: "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "Netherlands Antilles": "AN",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    Niue: "NU",
    "Northern Ireland": "GB",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    Palestine: "PS",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Pitcairn: "PN",
    Poland: "PL",
    Portugal: "PT",
    "Puerto Rico": "PR",
    Qatar: "QA",
    Reunion: "RE",
    Romania: "RO",
    Russia: "RU",
    Rwanda: "RW",
    "St Kitts & Nevis": "KN",
    "St Lucia": "LC",
    "St Martin": "MF",
    "St Pierre & Miquelon": "PM",
    "St Vincent & Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Saudi Arabia": "SA",
    Scotland: "GB",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    Swaziland: "SZ",
    Sweden: "SE",
    Switzerland: "CH",
    Syria: "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    Tanzania: "TZ",
    Thailand: "TH",
    "Timor L'Este": "TL",
    Togo: "TG",
    Tokelau: "TK",
    Tonga: "TO",
    "Trinidad & Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    "Turks & Caicos": "TC",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United States of America": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    Venezuela: "VE",
    Vietnam: "VN",
    "Virgin Islands (US)": "VI",
    Yemen: "YE",
    Wales: "GB",
    Zambia: "ZM",
    Zimbabwe: "ZW",
  },

  REGIONS: {
    Afghanistan: {
      name: "Afghanistan",
      code: "AF",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Albania: {
      name: "Albania",
      code: "AL",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Algeria: {
      name: "Algeria",
      code: "DZ",
      region: "Africa",
      subregion: "Northern Africa",
    },
    Andorra: {
      name: "Andorra",
      code: "AD",
      region: "Europe",
      subregion: "Southern Europe",
    },
    Angola: {
      name: "Angola",
      code: "AO",
      region: "Africa",
      subregion: "Middle Africa",
    },
    Anguilla: {
      name: "Anguilla",
      code: "AI",
      region: "Americas",
      subregion: "Caribbean",
    },
    "Antigua & Barbuda": {
      name: "Antigua & Barbuda",
      code: "AG",
      region: "Americas",
      subregion: "Caribbean",
    },
    Argentina: {
      name: "Argentina",
      code: "AR",
      region: "Americas",
      subregion: "South America",
    },
    Armenia: {
      name: "Armenia",
      code: "AM",
      region: "Asia",
      subregion: "Western Asia",
    },
    Aruba: {
      name: "Aruba",
      code: "AW",
      region: "Americas",
      subregion: "Caribbean",
    },
    Australia: {
      name: "Australia",
      code: "AU",
      region: "Oceania",
      subregion: "Australia & New Zealand",
    },
    Austria: {
      name: "Austria",
      code: "AT",
      region: "Europe",
      subregion: "Central Europe",
    },
    Azerbaijan: {
      name: "Azerbaijan",
      code: "AZ",
      region: "Asia",
      subregion: "Western Asia",
    },
    Bahamas: {
      name: "Bahamas",
      code: "BS",
      region: "Americas",
      subregion: "Caribbean",
    },
    Bahrain: {
      name: "Bahrain",
      code: "BH",
      region: "Asia",
      subregion: "Western Asia",
    },
    Bangladesh: {
      name: "Bangladesh",
      code: "BD",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Barbados: {
      name: "Barbados",
      code: "BB",
      region: "Americas",
      subregion: "Caribbean",
    },
    Belarus: {
      name: "Belarus",
      code: "BY",
      region: "Europe",
      subregion: "Eastern Europe",
    },
    Belgium: {
      name: "Belgium",
      code: "BE",
      region: "Europe",
      subregion: "Western Europe",
    },
    Belize: {
      name: "Belize",
      code: "BZ",
      region: "Americas",
      subregion: "Central America",
    },
    Benin: {
      name: "Benin",
      code: "BJ",
      region: "Africa",
      subregion: "Western Africa",
    },
    Bermuda: {
      name: "Bermuda",
      code: "BM",
      region: "Americas",
      subregion: "North America",
    },
    Bhutan: {
      name: "Bhutan",
      code: "BT",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Bolivia: {
      name: "Bolivia",
      code: "BO",
      region: "Americas",
      subregion: "South America",
    },
    "Bosnia & Herzegovina": {
      name: "Bosnia & Herzegovina",
      code: "BA",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Botswana: {
      name: "Botswana",
      code: "BW",
      region: "Africa",
      subregion: "Southern Africa",
    },
    Brazil: {
      name: "Brazil",
      code: "BR",
      region: "Americas",
      subregion: "South America",
    },
    "British Virgin Islands": {
      name: "British Virgin Islands",
      code: "VG",
      region: "Americas",
      subregion: "Caribbean",
    },
    Brunei: {
      name: "Brunei",
      code: "BN",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Bulgaria: {
      name: "Bulgaria",
      code: "BG",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    "Burkina Faso": {
      name: "Burkina Faso",
      code: "BF",
      region: "Africa",
      subregion: "Western Africa",
    },
    Burundi: {
      name: "Burundi",
      code: "BI",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Cambodia: {
      name: "Cambodia",
      code: "KH",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Cameroon: {
      name: "Cameroon",
      code: "CM",
      region: "Africa",
      subregion: "Middle Africa",
    },
    Canada: {
      name: "Canada",
      code: "CA",
      region: "Americas",
      subregion: "North America",
    },
    "Cape Verde": {
      name: "Cape Verde",
      code: "CV",
      region: "Africa",
      subregion: "Western Africa",
    },
    "Cayman Islands": {
      name: "Cayman Islands",
      code: "KY",
      region: "Americas",
      subregion: "Caribbean",
    },
    "Central African Republic": {
      name: "Central African Republic",
      code: "CF",
      region: "Africa",
      subregion: "Central Africa",
    },
    Chad: {
      name: "Chad",
      code: "TD",
      region: "Africa",
      subregion: "Middle Africa",
    },
    Chile: {
      name: "Chile",
      code: "CL",
      region: "Americas",
      subregion: "South America",
    },
    China: {
      name: "China",
      code: "CN",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Colombia: {
      name: "Colombia",
      code: "CO",
      region: "Americas",
      subregion: "South America",
    },
    Congo: {
      name: "Congo",
      code: "CG",
      region: "Africa",
      subregion: "Middle Africa",
    },
    "Cook Islands": {
      name: "Cook Islands",
      code: "CK",
      region: "Oceania",
      subregion: "Polynesia",
    },
    "Costa Rica": {
      name: "Costa Rica",
      code: "CR",
      region: "Americas",
      subregion: "Central America",
    },
    "Cote D Ivoire": {
      name: "Cote D Ivoire",
      code: "CI",
      region: "Africa",
      subregion: "Western Africa",
    },
    Croatia: {
      name: "Croatia",
      code: "HR",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Cuba: {
      name: "Cuba",
      code: "CU",
      region: "Americas",
      subregion: "Caribbean",
    },
    Cyprus: {
      name: "Cyprus",
      code: "CY",
      region: "Europe",
      subregion: "Southern Europe",
    },
    "Czech Republic": {
      name: "Czech Republic",
      code: "CZ",
      region: "Europe",
      subregion: "Central Europe",
    },
    "Democratic Republic of the Congo": {
      name: "Democratic Republic of the Congo",
      code: "CD",
      region: "Africa",
      subregion: "Central Africa",
    },
    Denmark: {
      name: "Denmark",
      code: "DK",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Djibouti: {
      name: "Djibouti",
      code: "DJ",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Dominica: {
      name: "Dominica",
      code: "DM",
      region: "Americas",
      subregion: "Caribbean",
    },
    "Dominican Republic": {
      name: "Dominican Republic",
      code: "DO",
      region: "Americas",
      subregion: "Caribbean",
    },
    Ecuador: {
      name: "Ecuador",
      code: "EC",
      region: "Americas",
      subregion: "South America",
    },
    Egypt: {
      name: "Egypt",
      code: "EG",
      region: "Africa",
      subregion: "Northern Africa",
    },
    "El Salvador": {
      name: "El Salvador",
      code: "SV",
      region: "Americas",
      subregion: "Central America",
    },
    England: {
      name: "England",
      code: "GB",
      region: "Europe",
      subregion: "Northern Europe",
    },
    "Equatorial Guinea": {
      name: "Equatorial Guinea",
      code: "GQ",
      region: "Africa",
      subregion: "Middle Africa",
    },
    Estonia: {
      name: "Estonia",
      code: "EE",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Ethiopia: {
      name: "Ethiopia",
      code: "ET",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    "Falkland Islands": {
      name: "Falkland Islands",
      code: "FK",
      region: "Americas",
      subregion: "South America",
    },
    "Faroe Islands": {
      name: "Faroe Islands",
      code: "FO",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Fiji: {
      name: "Fiji",
      code: "FJ",
      region: "Oceania",
      subregion: "Melanesia",
    },
    Finland: {
      name: "Finland",
      code: "FI",
      region: "Europe",
      subregion: "Northern Europe",
    },
    France: {
      name: "France",
      code: "FR",
      region: "Europe",
      subregion: "Western Europe",
    },
    "French Polynesia": {
      name: "French Polynesia",
      code: "PF",
      region: "Oceania",
      subregion: "Polynesia",
    },
    Gabon: {
      name: "Gabon",
      code: "GA",
      region: "Africa",
      subregion: "Middle Africa",
    },
    Gambia: {
      name: "Gambia",
      code: "GM",
      region: "Africa",
      subregion: "Western Africa",
    },
    Georgia: {
      name: "Georgia",
      code: "GE",
      region: "Asia",
      subregion: "Western Asia",
    },
    Germany: {
      name: "Germany",
      code: "DE",
      region: "Europe",
      subregion: "Western Europe",
    },
    Ghana: {
      name: "Ghana",
      code: "GH",
      region: "Africa",
      subregion: "Western Africa",
    },
    Gibraltar: {
      name: "Gibraltar",
      code: "GI",
      region: "Europe",
      subregion: "Southern Europe",
    },
    Greece: {
      name: "Greece",
      code: "GR",
      region: "Europe",
      subregion: "Southern Europe",
    },
    Greenland: {
      name: "Greenland",
      code: "GL",
      region: "Americas",
      subregion: "North America",
    },
    Grenada: {
      name: "Grenada",
      code: "GD",
      region: "Americas",
      subregion: "Caribbean",
    },
    Guam: {
      name: "Guam",
      code: "GU",
      region: "Oceania",
      subregion: "Micronesia",
    },
    Guatemala: {
      name: "Guatemala",
      code: "GT",
      region: "Americas",
      subregion: "Central America",
    },
    Guernsey: {
      name: "Guernsey",
      code: "GG",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Guinea: {
      name: "Guinea",
      code: "GN",
      region: "Africa",
      subregion: "Western Africa",
    },
    "Guinea Bissau": {
      name: "Guinea Bissau",
      code: "GW",
      region: "Africa",
      subregion: "Western Africa",
    },
    Guyana: {
      name: "Guyana",
      code: "GY",
      region: "Americas",
      subregion: "South America",
    },
    Haiti: {
      name: "Haiti",
      code: "HT",
      region: "Americas",
      subregion: "Caribbean",
    },
    Honduras: {
      name: "Honduras",
      code: "HN",
      region: "Americas",
      subregion: "Central America",
    },
    "Hong Kong": {
      name: "Hong Kong",
      code: "HK",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Hungary: {
      name: "Hungary",
      code: "HU",
      region: "Europe",
      subregion: "Central Europe",
    },
    Iceland: {
      name: "Iceland",
      code: "IS",
      region: "Europe",
      subregion: "Northern Europe",
    },
    India: {
      name: "India",
      code: "IN",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Indonesia: {
      name: "Indonesia",
      code: "ID",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Iran: {
      name: "Iran",
      code: "IR",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Iraq: {
      name: "Iraq",
      code: "IQ",
      region: "Asia",
      subregion: "Western Asia",
    },
    "Republic of Ireland": {
      name: "Republic of Ireland",
      code: "IE",
      region: "Europe",
      subregion: "Northern Europe",
    },
    "Isle of Man": {
      name: "Isle of Man",
      code: "IM",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Israel: {
      name: "Israel",
      code: "IL",
      region: "Asia",
      subregion: "Western Asia",
    },
    Italy: {
      name: "Italy",
      code: "IT",
      region: "Europe",
      subregion: "Southern Europe",
    },
    Jamaica: {
      name: "Jamaica",
      code: "JM",
      region: "Americas",
      subregion: "Caribbean",
    },
    Japan: {
      name: "Japan",
      code: "JP",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Jersey: {
      name: "Jersey",
      code: "JE",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Jordan: {
      name: "Jordan",
      code: "JO",
      region: "Asia",
      subregion: "Western Asia",
    },
    Kazakhstan: {
      name: "Kazakhstan",
      code: "KZ",
      region: "Asia",
      subregion: "Central Asia",
    },
    Kenya: {
      name: "Kenya",
      code: "KE",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Kosovo: {
      name: "Kosovo",
      code: "XK",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Kuwait: {
      name: "Kuwait",
      code: "KW",
      region: "Asia",
      subregion: "Western Asia",
    },
    "Kyrgyz Republic": {
      name: "Kyrgyz Republic",
      code: "KG",
      region: "Asia",
      subregion: "Central Asia",
    },
    Laos: {
      name: "Laos",
      code: "LA",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Latvia: {
      name: "Latvia",
      code: "LV",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Lebanon: {
      name: "Lebanon",
      code: "LB",
      region: "Asia",
      subregion: "Western Asia",
    },
    Lesotho: {
      name: "Lesotho",
      code: "LS",
      region: "Africa",
      subregion: "Southern Africa",
    },
    Liberia: {
      name: "Liberia",
      code: "LR",
      region: "Africa",
      subregion: "Western Africa",
    },
    Libya: {
      name: "Libya",
      code: "LY",
      region: "Africa",
      subregion: "Northern Africa",
    },
    Liechtenstein: {
      name: "Liechtenstein",
      code: "LI",
      region: "Europe",
      subregion: "Western Europe",
    },
    Lithuania: {
      name: "Lithuania",
      code: "LT",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Luxembourg: {
      name: "Luxembourg",
      code: "LU",
      region: "Europe",
      subregion: "Western Europe",
    },
    Macau: {
      name: "Macau",
      code: "MO",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Madagascar: {
      name: "Madagascar",
      code: "MG",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Malawi: {
      name: "Malawi",
      code: "MW",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Malaysia: {
      name: "Malaysia",
      code: "MY",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Maldives: {
      name: "Maldives",
      code: "MV",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Mali: {
      name: "Mali",
      code: "ML",
      region: "Africa",
      subregion: "Western Africa",
    },
    Malta: {
      name: "Malta",
      code: "MT",
      region: "Europe",
      subregion: "Southern Europe",
    },
    Mauritania: {
      name: "Mauritania",
      code: "MR",
      region: "Africa",
      subregion: "Western Africa",
    },
    Mauritius: {
      name: "Mauritius",
      code: "MU",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Mexico: {
      name: "Mexico",
      code: "MX",
      region: "Americas",
      subregion: "North America",
    },
    Moldova: {
      name: "Moldova",
      code: "MD",
      region: "Europe",
      subregion: "Eastern Europe",
    },
    Monaco: {
      name: "Monaco",
      code: "MC",
      region: "Europe",
      subregion: "Western Europe",
    },
    Mongolia: {
      name: "Mongolia",
      code: "MN",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Montenegro: {
      name: "Montenegro",
      code: "ME",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Montserrat: {
      name: "Montserrat",
      code: "MS",
      region: "Americas",
      subregion: "Caribbean",
    },
    Morocco: {
      name: "Morocco",
      code: "MA",
      region: "Africa",
      subregion: "Northern Africa",
    },
    Mozambique: {
      name: "Mozambique",
      code: "MZ",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Myanmar: {
      name: "Myanmar",
      code: "MM",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Namibia: {
      name: "Namibia",
      code: "NA",
      region: "Africa",
      subregion: "Southern Africa",
    },
    Nepal: {
      name: "Nepal",
      code: "NP",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Netherlands: {
      name: "Netherlands",
      code: "NL",
      region: "Europe",
      subregion: "Western Europe",
    },
    "New Caledonia": {
      name: "New Caledonia",
      code: "NC",
      region: "Oceania",
      subregion: "Melanesia",
    },
    "New Zealand": {
      name: "New Zealand",
      code: "NZ",
      region: "Oceania",
      subregion: "Australia & New Zealand",
    },
    Nicaragua: {
      name: "Nicaragua",
      code: "NI",
      region: "Americas",
      subregion: "Central America",
    },
    Niger: {
      name: "Niger",
      code: "NE",
      region: "Africa",
      subregion: "Western Africa",
    },
    Nigeria: {
      name: "Nigeria",
      code: "NG",
      region: "Africa",
      subregion: "Western Africa",
    },
    Macedonia: {
      name: "Macedonia",
      code: "MK",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    "Northern Ireland": {
      name: "Northern Ireland",
      code: "GB",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Norway: {
      name: "Norway",
      code: "NO",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Oman: {
      name: "Oman",
      code: "OM",
      region: "Asia",
      subregion: "Western Asia",
    },
    Pakistan: {
      name: "Pakistan",
      code: "PK",
      region: "Asia",
      subregion: "Southern Asia",
    },
    Palestine: {
      name: "Palestine",
      code: "PS",
      region: "Asia",
      subregion: "Western Asia",
    },
    Panama: {
      name: "Panama",
      code: "PA",
      region: "Americas",
      subregion: "Central America",
    },
    "Papua New Guinea": {
      name: "Papua New Guinea",
      code: "PG",
      region: "Oceania",
      subregion: "Melanesia",
    },
    Paraguay: {
      name: "Paraguay",
      code: "PY",
      region: "Americas",
      subregion: "South America",
    },
    Peru: {
      name: "Peru",
      code: "PE",
      region: "Americas",
      subregion: "South America",
    },
    Philippines: {
      name: "Philippines",
      code: "PH",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Poland: {
      name: "Poland",
      code: "PL",
      region: "Europe",
      subregion: "Central Europe",
    },
    Portugal: {
      name: "Portugal",
      code: "PT",
      region: "Europe",
      subregion: "Southern Europe",
    },
    "Puerto Rico": {
      name: "Puerto Rico",
      code: "PR",
      region: "Americas",
      subregion: "Caribbean",
    },
    Qatar: {
      name: "Qatar",
      code: "QA",
      region: "Asia",
      subregion: "Western Asia",
    },
    Reunion: {
      name: "Reunion",
      code: "RE",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Romania: {
      name: "Romania",
      code: "RO",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Russia: {
      name: "Russia",
      code: "RU",
      region: "Europe",
      subregion: "Eastern Europe",
    },
    Rwanda: {
      name: "Rwanda",
      code: "RW",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    "Saint Pierre & Miquelon": {
      name: "Saint Pierre & Miquelon",
      code: "PM",
      region: "Americas",
      subregion: "North America",
    },
    Samoa: {
      name: "Samoa",
      code: "WS",
      region: "Oceania",
      subregion: "Polynesia",
    },
    "San Marino": {
      name: "San Marino",
      code: "SM",
      region: "Europe",
      subregion: "Southern Europe",
    },
    "Saudi Arabia": {
      name: "Saudi Arabia",
      code: "SA",
      region: "Asia",
      subregion: "Western Asia",
    },
    Scotland: {
      name: "Scotland",
      code: "GB",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Senegal: {
      name: "Senegal",
      code: "SN",
      region: "Africa",
      subregion: "Western Africa",
    },
    Serbia: {
      name: "Serbia",
      code: "RS",
      region: "Europe",
      subregion: "Southeast Europe",
    },
    Seychelles: {
      name: "Seychelles",
      code: "SC",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    "Sierra Leone": {
      name: "Sierra Leone",
      code: "SL",
      region: "Africa",
      subregion: "Western Africa",
    },
    Singapore: {
      name: "Singapore",
      code: "SG",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Slovakia: {
      name: "Slovakia",
      code: "SK",
      region: "Europe",
      subregion: "Central Europe",
    },
    Slovenia: {
      name: "Slovenia",
      code: "SI",
      region: "Europe",
      subregion: "Central Europe",
    },
    Somalia: {
      name: "Somalia",
      code: "SO",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    "South Africa": {
      name: "South Africa",
      code: "ZA",
      region: "Africa",
      subregion: "Southern Africa",
    },
    "South Korea": {
      name: "South Korea",
      code: "KR",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Spain: {
      name: "Spain",
      code: "ES",
      region: "Europe",
      subregion: "Southern Europe",
    },
    "Sri Lanka": {
      name: "Sri Lanka",
      code: "LK",
      region: "Asia",
      subregion: "Southern Asia",
    },
    "St Kitts & Nevis": {
      name: "St Kitts & Nevis",
      code: "KN",
      region: "Americas",
      subregion: "Caribbean",
    },
    "St Lucia": {
      name: "St Lucia",
      code: "LC",
      region: "Americas",
      subregion: "Caribbean",
    },
    "St Vincent": {
      name: "St Vincent & the Grenadines",
      code: "VC",
      region: "Americas",
      subregion: "Caribbean",
    },
    Sudan: {
      name: "Sudan",
      code: "SD",
      region: "Africa",
      subregion: "Northern Africa",
    },
    Suriname: {
      name: "Suriname",
      code: "SR",
      region: "Americas",
      subregion: "South America",
    },
    Swaziland: {
      name: "Eswatini",
      code: "SZ",
      region: "Africa",
      subregion: "Southern Africa",
    },
    Sweden: {
      name: "Sweden",
      code: "SE",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Switzerland: {
      name: "Switzerland",
      code: "CH",
      region: "Europe",
      subregion: "Western Europe",
    },
    Syria: {
      name: "Syria",
      code: "SY",
      region: "Asia",
      subregion: "Western Asia",
    },
    Taiwan: {
      name: "Taiwan",
      code: "TW",
      region: "Asia",
      subregion: "Eastern Asia",
    },
    Tajikistan: {
      name: "Tajikistan",
      code: "TJ",
      region: "Asia",
      subregion: "Central Asia",
    },
    Tanzania: {
      name: "Tanzania",
      code: "TZ",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Thailand: {
      name: "Thailand",
      code: "TH",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    "Timor L'Este": {
      name: "Timor L'Este",
      code: "TL",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    Togo: {
      name: "Togo",
      code: "TG",
      region: "Africa",
      subregion: "Western Africa",
    },
    Tonga: {
      name: "Tonga",
      code: "TO",
      region: "Oceania",
      subregion: "Polynesia",
    },
    "Trinidad & Tobago": {
      name: "Trinidad & Tobago",
      code: "TT",
      region: "Americas",
      subregion: "Caribbean",
    },
    Tunisia: {
      name: "Tunisia",
      code: "TN",
      region: "Africa",
      subregion: "Northern Africa",
    },
    Turkey: {
      name: "Turkey",
      code: "TR",
      region: "Asia",
      subregion: "Western Asia",
    },
    Turkmenistan: {
      name: "Turkmenistan",
      code: "TM",
      region: "Asia",
      subregion: "Central Asia",
    },
    "Turks & Caicos": {
      name: "Turks & Caicos",
      code: "TC",
      region: "Americas",
      subregion: "Caribbean",
    },
    Uganda: {
      name: "Uganda",
      code: "UG",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Ukraine: {
      name: "Ukraine",
      code: "UA",
      region: "Europe",
      subregion: "Eastern Europe",
    },
    "United Arab Emirates": {
      name: "United Arab Emirates",
      code: "AE",
      region: "Asia",
      subregion: "Western Asia",
    },
    "United States Minor Outlying Islands": {
      name: "United States Minor Outlying Islands",
      code: "UM",
      region: "Americas",
      subregion: "North America",
    },
    "United States of America": {
      name: "United States of America",
      code: "US",
      region: "Americas",
      subregion: "North America",
    },
    Uruguay: {
      name: "Uruguay",
      code: "UY",
      region: "Americas",
      subregion: "South America",
    },
    Uzbekistan: {
      name: "Uzbekistan",
      code: "UZ",
      region: "Asia",
      subregion: "Central Asia",
    },
    Venezuela: {
      name: "Venezuela",
      code: "VE",
      region: "Americas",
      subregion: "South America",
    },
    Vietnam: {
      name: "Vietnam",
      code: "VN",
      region: "Asia",
      subregion: "South-Eastern Asia",
    },
    "Virgin Islands (US)": {
      name: "United States Virgin Islands",
      code: "VI",
      region: "Americas",
      subregion: "Caribbean",
    },
    Wales: {
      name: "Wales",
      code: "GB",
      region: "Europe",
      subregion: "Northern Europe",
    },
    Yemen: {
      name: "Yemen",
      code: "YE",
      region: "Asia",
      subregion: "Western Asia",
    },
    Zambia: {
      name: "Zambia",
      code: "ZM",
      region: "Africa",
      subregion: "Eastern Africa",
    },
    Zimbabwe: {
      name: "Zimbabwe",
      code: "ZW",
      region: "Africa",
      subregion: "Eastern Africa",
    },
  },
};
